<template>
  <transition
    mode="out-in"
    name="fade"
  >
    <div
      v-if="
        notAllOptionInStopList &&
        !menuStore.StopListProductIds.has(productInList.ID) &&
        ((clientStore.orderTypeNotSelected &&
          (!productInList.AvailableByOrderType.CourierDelivery ||
            !productInList.AvailableByOrderType.NoShipment ||
            !productInList.AvailableByOrderType.InHall)) ||
          isProductUnavailable(productInList))
      "
      class="v-card-unavailable-message v-mb-sm"
    >
      <icon-old-general-information-circle
        class="v-card-unavailable-message--icon v-error-color v-mr-xxs"
      />
      <span
        v-if="stringIsNullOrWhitespace(productInList.NoOrderTypeReasonText)"
        class="v-card-unavailable-message--text v-error-color"
        v-html="translate('productCard.notAvailableByOrderType')"
      />
      <span
        v-else
        class="v-card-unavailable-message--text v-error-color"
        v-html="productInList.NoOrderTypeReasonText"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { Option, ProductInList } from '~types/menuStore'

import { useCommon } from '@arora/common'

const { productInList } = defineProps<{ productInList: ProductInList }>()

const clientStore = useClientStore()

const { stringIsNullOrWhitespace } = useCommon()
const { isProductUnavailable } = useProduct()

const { translate } = useI18nSanitized()
const menuStore = useMenuStore()

const notAllOptionInStopList = computed<boolean>(() =>
  ([] as Option[]).concat
    .apply([], Object.values(productInList.Options))
    .some((option) => !menuStore.StopListOptionIds.has(option.ID))
)
</script>

<style lang="scss">
.v-card-unavailable-message {
  display: flex;
  align-items: flex-start;
  width: 100%;

  .v-card-unavailable-message--icon {
    flex: 0 0 20px;
    width: 20px;
    height: auto;
    fill: none;
  }

  .v-card-unavailable-message--text {
    flex: 0 0 calc(100% - 20px);
    width: calc(100% - 20px);
    font-weight: 600;
  }
}
</style>
