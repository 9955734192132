<template>
  <icon-old-general-bookmark
    v-if="appConfig.VueSettingsPreRun.MenuFavoritesIcon === 'Bookmark'"
  />
  <template v-else-if="appConfig.VueSettingsPreRun.MenuFavoritesIcon === 'Heart'">
    <icon-old-general-broken-heart v-if="broken" />
    <icon-old-general-heart v-else />
  </template>
  <icon-old-general-star
    v-else-if="appConfig.VueSettingsPreRun.MenuFavoritesIcon === 'Star'"
  />
  <icon-old-general-thumb-Up
    v-else-if="appConfig.VueSettingsPreRun.MenuFavoritesIcon === 'Thumbs'"
  />
</template>

<script setup lang="ts">
const { broken = false } = defineProps<{
  broken?: boolean
}>()

const appConfig = useAppConfig()
</script>
